import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/global.css");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.2.4_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_219ea134d978966ccd66aa79059ee9e8/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.2.4_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_219ea134d978966ccd66aa79059ee9e8/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@15.2.4_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_219ea134d978966ccd66aa79059ee9e8/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/script.js");
